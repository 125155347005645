<template>
    <vxe-grid
            border
            show-overflow
            keep-source
            ref="xGrid"
            height="460"
            :loading="loading"
            :data="tableData"
            :mouse-config="{selected: false}"
            :columns="tableColumn"
            :toolbar-config="tableToolbar"
            :edit-config="{trigger: 'click', mode: 'cell', showStatus: true}"
            :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true, isEdit: true}"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
        <template #top>
            <el-alert type="warning" :title="`已选择 ${selectRecords.length} 项`" :closable="false" show-icon></el-alert>
        </template>
        <template #toolbar_buttons>
            <el-button @click="insertEvent">新增</el-button>
            <el-button @click="saveEvent">保存</el-button>
        </template>
    </vxe-grid>
</template>

<script>
    export default {
        data () {
            return {
                loading: false,
                tableData: [],
                selectRecords: [],
                tableColumn: [
                    {type: 'checkbox', width: 60},
                    {type: 'seq', title: 'Number', width: 80},
                    {field:'name', title: 'ElInput', minWidth: 140, editRender: {name: 'ElInput'}},
                    {field:'role', title: 'ElAutocomplete', width: 160, editRender: {name: 'ElAutocomplete', props: {fetchSuggestions: this.roleFetchSuggestions}}},
                    {field:'age', title: 'ElInputNumber', width: 160, editRender: {name: 'ElInputNumber', props: {max: 35, min: 18}}},
                    {field:'sex', title: 'ElSelect', width: 140, editRender: {name: 'ElSelect', options: [ {label: '', value: ''}, {label: '男', value: '1'}, {label: '女', value: '0'} ]}},
                    {field:'sex1', title: 'ElSelect（不建议放在单元格）', width: 260, editRender: {name: 'ElSelect', options: [], props: {multiple: true, clearable: true}}},
                    {field:'sex2', title: 'ElSelect', width: 140, editRender: {name: 'ElSelect', optionGroups: [], props: {clearable: true}}},
                    {field:'region', title: 'ElCascader', width: 200, editRender: {name: 'ElCascader', props: {options: []}}},
                    {field:'date', title: 'ElDatePicker', width: 200, editRender: {name: 'ElDatePicker', props: {type: 'date', format: 'yyyy/MM/dd'}}},
                    {field:'date1', title: 'DateTimePicker', width: 220, editRender: {name: 'ElDatePicker', props: {type: 'datetime', format: 'yyyy-MM-dd HH:mm:ss'}}},
                    {field:'date5', title: 'ElTimeSelect', width: 200, editRender: {name: 'ElTimeSelect', props: {pickerOptions: {start: '08:30', step: '00:15', end: '18:30'}}}},
                    {field:'flag', title: 'ElSwitch', width: 100, cellRender: {name: 'ElSwitch'}},
                    {field:'rate', title: 'ElRate', width: 200, cellRender: {name: 'ElRate'}}
                ],
                sexList: [
                    {label: '', value: ''},
                    {label: '男', value: '1'},
                    {label: '女', value: '0'}
                ],
                regionList: [
                    {
                        label: '北京',
                        value: 1,
                        children: [
                            {value: 3, label: '东城区'},
                            {value: 4, label: '西城区'}
                        ]
                   },
                    {
                        label: '上海',
                        value: 21,
                        children: [
                            {value: 23, label: '黄浦区'},
                            { value: 24, label: '卢湾区'}
                        ]
                   },
                    {
                        label: '广东',
                        value: 42,
                        children: [
                            { value: 43, label: '广州市'},
                            { value: 67, label: '深圳市'}
                        ]
                   }
                ],
                tableToolbar: {
                    slots: {
                        buttons: 'toolbar_buttons'
                   }
               },
                restaurants: [
                    { value: '前端', name: '前端'},
                    { value: '后端', name: '后端'},
                    { value: '开发', name: '开发'},
                    { value: '测试', name: '测试'}
                ]
           }
       },
        created () {
            this.loading = true
            setTimeout(() => {
                this.tableData = [
                    { id: 10001, name: 'Test1', nickname: 'T1', role: 'Develop', sex: '0', sex1: [], region: [], age: 28, date: '', date1: '', date2: '', date3: '', date4: [], date5: '', date7: '', color1: '', tree1: '', tree2: [], rate: 5, rate1: 59, flag: false, address: 'Shenzhen'},
                    { id: 10002, name: 'Test2', nickname: 'T2', role: 'Test', sex: '1', sex1: [], region: [], age: 22, date: '', date1: '', date2: '', date3: '', date4: [], date5: '', date7: '', color1: '', tree1: '', tree2: [], rate: 2, rate1: 22, flag: false, address: 'Guangzhou'},
                    { id: 10003, name: 'Test3', nickname: 'T3', role: 'PM', sex: '0', sex1: [], region: [], age: 32, date: '', date1: '', date2: '', date3: '', date4: [], date5: '', date7: '', color1: '', tree1: '', tree2: [], rate: 3, rate1: 12, flag: false, address: 'Shanghai'},
                    { id: 10004, name: 'Test4', nickname: 'T4', role: 'Designer', sex: '0', sex1: ['1', '0'], region: [], age: 23, date: '', date1: '', date2: '', date3: '', date4: [], date5: '', color1: '', tree1: '', tree2: [], date7: '', rate: 33, rate1: 4, flag: true, address: 'Shenzhen'},
                    { id: 10005, name: 'Test5', nickname: 'T5', role: 'Develop', sex: '0', sex1: ['1', '0'], region: [], age: 30, date: '', date1: '', date2: '', date3: '', date4: [], date5: '', color1: '', tree1: '', tree2: [], date7: '', rate: 0, rate1: 15, flag: true, address: 'Shanghai'},
                    { id: 10006, name: 'Test6', nickname: 'T6', role: 'Designer', sex: '0', sex1: [], region: [], age: 21, date: '', date1: '', date2: '', date3: '', date4: [], date5: '', date7: '', color1: '', tree1: '', tree2: [], rate: 3, rate1: 73, flag: false, address: 'Shenzhen'},
                    { id: 10007, name: 'Test7', nickname: 'T7', role: 'Test', sex: '1', sex1: ['1'], region: [], age: 29, date: '', date1: '', date2: '', date3: '', date4: [], date5: '', date7: '', color1: '', tree1: '', tree2: [], rate: 0, rate1: 0, flag: true, address: 'Guangzhou'},
                    { id: 10008, name: 'Test8', nickname: 'T8', role: 'Develop', sex: '1', sex1: [], region: [], age: 35, date: '', date1: '', date2: '', date3: '', date4: [], date5: '', date7: '', color1: '', tree1: '', tree2: [], rate: 2, rate1: 14, flag: false, address: 'Shenzhen'},
                    { id: 10009, name: 'Test9', nickname: 'T9', role: 'Test', sex: '1', sex1: ['0'], region: [], age: 24, date: '', date1: '', date2: '', date3: '', date4: [], date5: '', date7: '', color1: '', tree1: '', tree2: [], rate: 3, rate1: 52, flag: false, address: 'Shenzhen'},
                    { id: 100010, name: 'Test10', nickname: 'T10', role: 'Develop', sex: '1', sex1: [], region: [], age: 20, date: '', date1: '', date2: '', date3: '', date4: [], date5: '', date7: '', color1: '', tree1: '', tree2: [], rate: 4, rate1: 83, flag: false, address: 'Guangzhou'}
                ]
                this.loading = false
           }, 500)
            //this.tableColumn[5].editRender.options = this.sexList
            //this.tableColumn[6].editRender.options = this.sexList
            //this.tableColumn[8].editRender.props.options = this.regionList
            //this.findSexGroupList()
       },
        methods: {
            findSexGroupList () {
                let sexGroupList = [
                    {
                        label: '分组1',
                        options: [
                            {
                                label: '男',
                                value: '1'
                           }
                        ]
                   },
                    {
                        label: '分组2',
                        options: [
                            {
                                label: '女',
                                value: '0'
                           }
                        ]
                   }
                ]
                this.tableColumn[7].editRender.optionGroups = sexGroupList
           },
            insertEvent () {
                let xGrid = this.$refs.xGrid
                let record = {
                    role: '',
                    age: 18,
                    sex1: [],
                    region: [],
                    flag: false,
                    rate: 2
               }
                xGrid.insert(record).then(({ row}) => xGrid.setActiveRow(row))
           },
            saveEvent () {
                let { insertRecords, removeRecords, updateRecords} = this.$refs.xGrid.getRecordset()
                if (insertRecords.length || removeRecords.length || updateRecords.length) {
                    this.$alert(`insertRecords=${insertRecords.length}; removeRecords=${removeRecords.length}; updateRecords=${updateRecords.length}`)
               } else {
                    this.$alert('数据未改动！')
               }
           },
            roleFetchSuggestions (queryString, cb) {
                var restaurants = this.restaurants
                var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants
                clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    cb(results)
               }, 1000 * Math.random())
           },
            createStateFilter (queryString) {
                return (state) => {
                    return (state.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
               }
           },
            checkboxChangeEvent () {
                this.selectRecords = this.$refs.xGrid.getCheckboxRecords()
           }
       }
   }


</script>





<style>
</style>